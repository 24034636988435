<script setup>
import { Head, Link, useForm } from "@inertiajs/vue3";
import AuthenticationCard from "@/Components/AuthenticationCard.vue";
import InputError from "@/Components/InputError.vue";
import InputLabel from "@/Components/InputLabel.vue";
import ApplicationMark from "@/Components/ApplicationMark.vue";
import { inject, ref } from "vue";
import SignInWIthGoogleButton from "@/Pages/Auth/SignInWIthGoogleButton.vue";
import SignInButton from "@/Pages/Auth/SignInButton.vue";

const route = inject("route");
defineProps({
    canResetPassword: {
        type: Boolean,
        required: true,
    },
    status: {
        type: String,
        default: "",
    },
});

const form = useForm({
    email: "",
    password: "",
    remember: false,
});

const showForm = ref(false);

const submit = () => {
    form.transform((data) => ({
        ...data,
        remember: form.remember ? "on" : "",
    })).post(route("login"), {
        onFinish: () => form.reset("password"),
    });
};
</script>

<template>
    <Head title="Log in" />

    <AuthenticationCard>
        <template #logo>
            <ApplicationMark class="mb-5 block h-28 w-auto" />
        </template>

        <div
            v-if="status"
            class="mb-4 text-sm font-medium text-green-600"
        >
            {{ status }}
        </div>

        <div
            class="flex justify-center"
            v-if="!showForm"
        >
            <div>
                <SignInWIthGoogleButton />

                <div class="mt-5 text-center">or</div>

                <hr class="mx-auto mt-5 w-48 border-thomann-red" />

                <SignInButton
                    class="mx-auto mt-5 flex w-48 justify-center"
                    @click="showForm = true"
                >
                    sign in
                </SignInButton>
            </div>
        </div>

        <Transition
            enter-active-class="transition ease-out duration-300"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-200"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <form
                @submit.prevent="submit"
                v-if="showForm"
            >
                <div>
                    <InputLabel
                        for="email"
                        value="email address"
                        class="text-gray-fr-500"
                    />

                    <input
                        id="email"
                        v-model="form.email"
                        type="email"
                        class="mt-1 block w-full border-0 border-b border-black ring-0 focus:ring-0"
                        required
                        autofocus
                        autocomplete="username"
                    />

                    <InputError
                        class="mt-2"
                        :message="form.errors.email"
                    />
                </div>

                <div class="mt-4 text-gray-fr-500">
                    <InputLabel
                        for="password"
                        value="password"
                        class="text-gray-fr-500"
                    />

                    <input
                        id="password"
                        v-model="form.password"
                        type="password"
                        class="mt-1 block w-full border-0 border-b border-black ring-0 focus:ring-0"
                        required
                        autocomplete="current-password"
                    />

                    <div class="mt-3 text-right">
                        <Link
                            v-if="canResetPassword"
                            :href="route('password.request')"
                            class="rounded-md text-sm text-gray-600 underline hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            forgot password?
                        </Link>
                    </div>

                    <InputError
                        class="mt-2"
                        :message="form.errors.password"
                    />
                </div>

                <!--            <div class="block mt-4">-->
                <!--                <label class="flex items-center">-->
                <!--                    <Checkbox-->
                <!--                        v-model:checked="form.remember"-->
                <!--                        name="remember"-->
                <!--                    />-->

                <!--                    <span class="ml-2 text-sm text-gray-600">Remember me</span>-->
                <!--                </label>-->
                <!--            </div>-->

                <div class="mt-4 flex items-center justify-center">
                    <SignInButton
                        class="w-48"
                        :class="{ 'opacity-25': form.processing }"
                        :disabled="form.processing"
                    >
                        sign in
                    </SignInButton>
                </div>

                <div class="mt-5 text-center">or</div>

                <hr class="mx-auto mt-5 w-48 border-thomann-red" />

                <div class="flex justify-center">
                    <SignInWIthGoogleButton class="mt-5" />
                </div>
            </form>
        </Transition>
    </AuthenticationCard>
</template>
